// @flow
import "core/components/global/global.scss";
import "./components/global.scss";
import * as Sentry from "@sentry/react"; // eslint-disable-line import/no-unresolved
import invariant from "invariant";
import * as React from "react";
import { render } from "react-dom";
import { setAppElement } from "react-modal";
import { Provider, useSelector } from "react-redux";
import history from "abstract-di/history";
import trackHistory from "core/analytics";
import Collection from "core/components/Collection";
import Layer from "core/components/Layer";
import OrganizationPeople from "core/components/OrganizationPeople";
import OrganizationReporting from "core/components/OrganizationReporting";
import OrganizationTeam from "core/components/OrganizationTeam";
import OrganizationTeamMembers from "core/components/OrganizationTeamMembers";
import OrganizationTeamProjects from "core/components/OrganizationTeamProjects";
import OrganizationTeamSettings from "core/components/OrganizationTeamSettings";
import OrganizationTeams from "core/components/OrganizationTeams";
import ProjectCollections from "core/components/ProjectCollections";
import ProjectMembers from "core/components/ProjectMembers";
import ProjectSettingsShell from "core/components/ProjectSettings";
import ProjectSettingsDetails from "core/components/ProjectSettings/Details";
import ProjectSettingRestrictions from "core/components/ProjectSettings/Restrictions";
import Reviews from "core/components/Reviews";
import Theme from "core/components/Theme";
import TokenAuthentication from "core/components/TokenAuthentication";
import CollectionLayer from "core/containers/CollectionLayer";
import window from "core/global/window";
import withProfiler from "core/hocs/withProfiler";
import { removeExperiments } from "core/lib/experiments";
import { ModalRouter, V3Routes, V3Route, V3Redirect } from "core/lib/router";
import { enableSentry } from "core/lib/sentry";
import AuthorizeOAuthApplication from "web/components/AuthorizeOAuthApplication";
import PluginRedirect from "web/components/AuthorizeOAuthApplication/PluginRedirect";
import GlobalNavigationShell from "web/components/GlobalNavigationShell";
import GrantOrganizationAccess from "web/components/GrantOrganizationAccess";
import GrantProjectAccess from "web/components/GrantProjectAccess";
import LayerPresentationRedirect from "web/components/LayerPresentationRedirect";
import ForgotPassword from "web/components/Onboarding/ForgotPassword";
import InvitationConfirmation from "web/components/Onboarding/InvitationConfirmation";
import SignUp from "web/components/Onboarding/SignUp";
import Organization from "web/components/Organization";
import OAuthApplicationDetails from "web/components/ProfileSettings/OAuthApplications/Details";
import OAuthApplications from "web/components/ProfileSettings/OAuthApplications/List";
import ProjectOverview from "web/components/ProjectOverview";
import WebhookDeliveries from "web/components/WebhookOverview/WebhookDeliveries";
import WebhookDetails from "web/components/WebhookOverview/WebhookDetails";
import WebhookEvents from "web/components/WebhookOverview/WebhookEvents";
import WebhookPayload from "web/components/WebhookOverview/WebhookPayload";
import WebhookOverview from "web/containers/WebhookOverview";
import { analytics, trackVisits } from "./analytics";
import AppShell from "./components/AppShell";
import Branch from "./components/BranchShell";
import OrganizationEmojis from "./components/Emojis";
import { SlackIntegrationComplete } from "./components/IntegrationComplete";
import LoadSession from "./components/LoadSession";
import AutoDownloadApp from "./components/Onboarding/AutoDownloadApp";
import OnboardingCheckInbox from "./components/Onboarding/CheckInbox";
import CompleteProfile from "./components/Onboarding/CompleteProfile";
import OnboardingCreateOrJoinOrganization from "./components/Onboarding/CreateOrJoinOrganization";
import CreateOrganization from "./components/Onboarding/CreateOrganization";
import DownloadApp from "./components/Onboarding/DownloadApp";
import OnboardingInviteTeam from "./components/Onboarding/InviteTeam";
import OnboardingOrganizationAccessRequest from "./components/Onboarding/OrganizationAccessRequest";
import OnboardingVerifyEmail from "./components/Onboarding/VerifyEmail";
import OrganizationOverviewProjects from "./components/Organization/Projects";
import OrganizationDetails from "./components/OrganizationSettings/Details";
import OrganizationAdminEmojis from "./components/OrganizationSettings/Emojis";
import OrganizationIntegrations from "./components/OrganizationSettings/Integrations";
import OrganizationPermissions from "./components/OrganizationSettings/Permissions";
import OrganizationSharing from "./components/OrganizationSettings/Sharing";
import OrganizationSubscription from "./components/OrganizationSettings/Subscription";
import UserNotifications from "./components/ProfileSettings/Notifications";
import RequireValidInvitation from "./components/RequireValidInvitation";
import SSOTestVerification from "./components/SSOTestVerification";
import Share from "./components/Share";
import IntegrationGeneral from "./components/SlackIntegrationSettings";
import { SENTRY_DSN, USE_MAIN_INSTEAD_OF_MASTER } from "./config";
import Commits from "./containers/BranchCommits";
import Files from "./containers/BranchFiles";
import BranchOverview from "./containers/BranchOverview";
import Commit from "./containers/Commit";
import File from "./containers/File";
import IntegrationSettingsShell from "./containers/IntegrationSettingsShell";
import Invitation from "./containers/InvitationFlow/Invitation";
import InvitationDomainRestricted from "./containers/InvitationFlow/InvitationDomainRestricted";
import NotFound from "./containers/NotFound";
import NotificationReader from "./containers/NotificationReader";
import OnboardingConfirmation from "./containers/Onboarding/OnboardingConfirmation";
import ResetPassword from "./containers/Onboarding/ResetPassword";
import SignIn from "./containers/Onboarding/SignIn";
import OnboardingEmailVerifier from "./containers/OnboardingEmailVerifier";
import OnboardingShell from "./containers/OnboardingShell";
import OrganizationActivity from "./containers/Organization/Activity";
import OrganizationSettingsShell from "./containers/OrganizationSettingsShell";
import UserAccessTokens from "./containers/ProfileSettings/AccessTokens";
import UserEmails from "./containers/ProfileSettings/EmailsEdit";
import UserOrganizations from "./containers/ProfileSettings/OrganizationsEdit";
import UserPassword from "./containers/ProfileSettings/PasswordEdit";
import UserProfile from "./containers/ProfileSettings/ProfileEdit";
import ProfileSettingsShell from "./containers/ProfileSettingsShell";
import ProjectActivity from "./containers/Project/Activity";
import ProjectBranches from "./containers/Project/Branches";
import ProjectShell from "./containers/ProjectShell";
import PusherProvider from "./containers/PusherProvider";
import RedirectToOrganization from "./containers/RedirectToOrganization";
import RequireOnboardingAuthentication from "./containers/RequireOnboardingAuthentication";
import RequireOnboardingFlag from "./containers/RequireOnboardingFlag";
import RequireSession from "./containers/RequireSession";
import RequireZendeskAuthentication from "./containers/RequireZendeskAuthentication";
import IntegrationProjectChannels from "./containers/SlackIntegrationSettings/ProjectChannels";
import SupportTicket from "./containers/SupportTicket";
import store from "./store";
const root = window.document.getElementById("root");

function StoreLoader({ children }) {
  const userHasSignedOut = useSelector(
    (state) => state.session.userHasSignedOut
  );
  const isLoaded = useSelector((state) => state.asyncInitialState.loaded);

  if (!isLoaded && !userHasSignedOut) {
    return null;
  }

  return children;
}

export const routes = (
  <V3Routes>
    <V3Route path="/zendesk/auth" component={RequireZendeskAuthentication} />
    <V3Route path="/token/auth" component={TokenAuthentication} />
    <V3Route path="/" component={RedirectToOrganization} />
    <V3Route component={OnboardingShell}>
      <V3Route component={RequireOnboardingAuthentication}>
        <V3Route component={RequireOnboardingFlag}>
          <V3Route
            path="/signup/create-or-join-organization"
            component={OnboardingCreateOrJoinOrganization}
          />
          <V3Route
            path="/signup/create-organization"
            component={CreateOrganization}
          />
          <V3Route path="/signup/invite" component={OnboardingInviteTeam} />
          <V3Route
            path="/signup/confirm-email"
            component={OnboardingCheckInbox}
          />
          <V3Route
            path="/signup/confirm-email/:token/verify"
            component={OnboardingVerifyEmail}
          />
          <V3Route
            path="/signup/join-organization"
            component={OnboardingOrganizationAccessRequest}
          />
        </V3Route>

        <V3Route
          path="/signup/confirmation"
          component={OnboardingConfirmation}
        />
        <V3Route path="/account/profile/complete" component={CompleteProfile} />
      </V3Route>
      <V3Route component={RequireValidInvitation}>
        <V3Route path="/invitations/:token" component={Invitation} />
        <V3Route
          path="/invitations/:token/confirmation"
          component={InvitationConfirmation}
        />
        <V3Route
          path="/invitations/:token/add-email"
          component={InvitationDomainRestricted}
        />
      </V3Route>
      <V3Route path="/signin" component={SignIn} />
      <V3Route path="/signup" component={SignUp} />
      <V3Route path="/download" component={DownloadApp} />
      <V3Route path="/autodownload" component={AutoDownloadApp} />
      <V3Route path="/password/new" component={ForgotPassword} />
      <V3Route path="/password/:id/edit" component={ResetPassword} />
      <V3Redirect path="/organizations/new" to="/signup" />
      <V3Redirect path="/start-branches-trial" to="/signup?branches=true" />
      <V3Redirect path="/password/:id" to="/password/:id/edit" />
      <V3Route path="/emails/:id/verify" component={OnboardingEmailVerifier} />
    </V3Route>

    <V3Route component={LoadSession}>
      <V3Route component={RequireSession}>
        <V3Route component={AppShell}>
          <V3Route component={NotificationReader}>
            <V3Route component={GlobalNavigationShell}>
              {/* Organization Overview Routes */}
              <V3Route component={Organization}>
                <V3Redirect
                  path="/organizations/:organizationId"
                  to="/organizations/:organizationId/projects"
                />
                <V3Route
                  path="/organizations/:organizationId/projects"
                  component={OrganizationOverviewProjects}
                  id="organizationProjects"
                />
                <V3Route
                  path="/organizations/:organizationId/activity"
                  component={withProfiler(
                    OrganizationActivity,
                    "OrganizationActivity"
                  )}
                  id="organization"
                />
                <V3Route
                  path="/organizations/:organizationId/people"
                  component={OrganizationPeople}
                  id="organizationPeople"
                />
                <V3Route
                  path="/organizations/:organizationId/teams"
                  component={OrganizationTeams}
                  id="organizationTeams"
                />
                <V3Route component={OrganizationTeam} id="organizationTeam">
                  <V3Redirect
                    path="/organizations/:organizationId/teams/:teamId"
                    to="/organizations/:organizationId/teams/:teamId/members"
                  />
                  <V3Route
                    path="/organizations/:organizationId/teams/:teamId/members"
                    component={OrganizationTeamMembers}
                    id="organizationTeamMembers"
                  />
                  <V3Route
                    path="/organizations/:organizationId/teams/:teamId/projects"
                    component={OrganizationTeamProjects}
                    id="organizationTeamProjects"
                  />
                  <V3Route
                    path="/organizations/:organizationId/teams/:teamId/settings"
                    component={OrganizationTeamSettings}
                    id="organizationTeamSettings"
                  />
                </V3Route>
                <V3Route
                  path="/organizations/:organizationId/reviews"
                  component={withProfiler(Reviews, "OrganizationReviews")}
                />
                <V3Route
                  path="/organizations/:organizationId/reporting"
                  component={OrganizationReporting}
                  id="organizationReporting"
                  hideAbstractAppButton
                />
                <V3Route
                  path="/organizations/:organizationId/emojis"
                  component={OrganizationEmojis}
                />

                {/* Organization Settings Routes */}
                <V3Route
                  component={OrganizationSettingsShell}
                  hideAbstractAppButton
                >
                  <V3Redirect
                    path="/organizations/:organizationId/edit"
                    to="/organizations/:organizationId/edit/details"
                  />
                  <V3Route
                    path="/organizations/:organizationId/edit/details"
                    component={OrganizationDetails}
                  />
                  <V3Route
                    path="/organizations/:organizationId/edit/permissions"
                    component={OrganizationPermissions}
                  />
                  <V3Route
                    path="/organizations/:organizationId/edit/integrations"
                    component={OrganizationIntegrations}
                    id="integrations"
                  />
                  <V3Route
                    path="/organizations/:organizationId/edit/sharing"
                    component={OrganizationSharing}
                  />
                  <V3Route
                    path="/organizations/:organizationId/edit/subscription"
                    component={OrganizationSubscription}
                  />
                  <V3Route
                    path="/organizations/:organizationId/edit/emojis"
                    component={OrganizationAdminEmojis}
                  />
                  {/* Support for old settings routes */}
                  <V3Redirect
                    path="/organizations/:organizationId/permissions/edit"
                    to="/organizations/:organizationId/edit/permissions"
                  />
                  <V3Redirect
                    path="/organizations/:organizationId/integrations/edit"
                    to="/organizations/:organizationId/edit/integrations"
                  />
                  <V3Redirect
                    path="/organizations/:organizationId/sharing/edit"
                    to="/organizations/:organizationId/edit/sharing"
                  />
                  <V3Redirect
                    path="/organizations/:organizationId/subscription/edit"
                    to="/organizations/:organizationId/edit/subscription"
                  />
                  <V3Route component={WebhookOverview}>
                    <V3Route
                      path="/organizations/:organizationId/webhooks/:webhookId/details"
                      component={WebhookDetails}
                      id="webhook-details"
                    />
                    <V3Route
                      path="/organizations/:organizationId/webhooks/:webhookId/events"
                      component={WebhookEvents}
                      id="webhook-events"
                    />
                    <V3Route
                      path="/organizations/:organizationId/webhooks/:webhookId/deliveries"
                      component={WebhookDeliveries}
                      id="webhook-deliveries"
                    />
                    <V3Route
                      path="/organizations/:organizationId/webhooks/:webhookId/deliveries/:webhookDeliveryId"
                      id="webhook-delivery"
                      component={WebhookPayload}
                    />
                  </V3Route>
                </V3Route>
              </V3Route>

              {/* Project Routes */}
              <V3Route component={ProjectShell}>
                <V3Route
                  path="/projects/:projectId"
                  component={ProjectOverview}
                  id="project"
                />
                <V3Route
                  path="/projects/:projectId/branches"
                  component={ProjectBranches}
                  id="branches"
                />
                <V3Route
                  path="/projects/:projectId/collections"
                  component={withProfiler(
                    ProjectCollections,
                    "ProjectCollections"
                  )}
                  id="collections"
                />
                <V3Route
                  path="/projects/:projectId/everyone"
                  component={ProjectMembers}
                  tab="everyone"
                  id="everyone"
                />
                <V3Route
                  path="/projects/:projectId/people"
                  component={ProjectMembers}
                  tab="people"
                  id="people"
                />
                <V3Route
                  path="/projects/:projectId/teams"
                  component={ProjectMembers}
                  tab="teams"
                  id="teams"
                />
                <V3Route
                  path="/projects/:projectId/activity"
                  component={withProfiler(ProjectActivity, "ProjectActivity")}
                  id="activity"
                />

                {/* Project Settings */}
                <V3Route component={ProjectSettingsShell}>
                  <V3Route
                    path="/projects/:projectId/edit"
                    component={ProjectSettingsDetails}
                  />
                  <V3Route
                    path="/projects/:projectId/edit/restrictions"
                    component={ProjectSettingRestrictions}
                  />
                </V3Route>

                {/* Branch Routes */}
                <V3Route component={Branch} id="branch">
                  <V3Redirect
                    path="/projects/:projectId/branches/:branchId/overview"
                    to="/projects/:projectId/branches/:branchId"
                  />
                  {USE_MAIN_INSTEAD_OF_MASTER === "true" && (
                    <V3Redirect
                      path="/projects/:projectId/branches/master"
                      to="/projects/:projectId/branches/main"
                    />
                  )}
                  <V3Route
                    path="/projects/:projectId/branches/:branchId"
                    component={BranchOverview}
                  />
                  {USE_MAIN_INSTEAD_OF_MASTER === "true" && (
                    <V3Redirect
                      path="/projects/:projectId/branches/master/commits"
                      to="/projects/:projectId/branches/main/commits"
                    />
                  )}
                  <V3Route
                    component={Commits}
                    path="/projects/:projectId/branches/:branchId/commits"
                  />
                  <V3Route component={Commits}>
                    {USE_MAIN_INSTEAD_OF_MASTER === "true" && (
                      <V3Redirect
                        path="/projects/:projectId/branches/master/commits/:sha"
                        to="/projects/:projectId/branches/main/commits/:sha"
                      />
                    )}
                    <V3Route
                      id="commit"
                      path="/projects/:projectId/branches/:branchId/commits/:sha"
                      component={Commit}
                    />
                  </V3Route>
                  {USE_MAIN_INSTEAD_OF_MASTER === "true" && (
                    <V3Redirect
                      path="/projects/:projectId/branches/master/files"
                      to="/projects/:projectId/branches/main/files"
                    />
                  )}
                  <V3Route
                    component={Files}
                    path="/projects/:projectId/branches/:branchId/files"
                  />
                  {USE_MAIN_INSTEAD_OF_MASTER === "true" && (
                    <V3Redirect
                      path="/projects/:projectId/branches/master/files/:fileId"
                      to="/projects/:projectId/branches/main/files/:fileId"
                    />
                  )}
                  {USE_MAIN_INSTEAD_OF_MASTER === "true" && (
                    <V3Redirect
                      path="/projects/:projectId/branches/master/files/:fileId/pages/:pageId"
                      to="/projects/:projectId/branches/main/files/:fileId/pages/:pageId"
                    />
                  )}
                  <V3Route component={Files}>
                    <V3Route
                      id="file"
                      path="/projects/:projectId/branches/:branchId/files/:fileId"
                      component={File}
                    />
                    <V3Route
                      id="page"
                      path="/projects/:projectId/branches/:branchId/files/:fileId/pages/:pageId"
                      component={File}
                    />
                  </V3Route>
                </V3Route>
              </V3Route>
            </V3Route>

            {/* Integration Settings Routes */}
            <V3Route component={IntegrationSettingsShell}>
              <V3Route
                path="/organizations/:organizationId/integrations/:integrationId/general"
                component={IntegrationGeneral}
              />
              <V3Route
                path="/organizations/:organizationId/integrations/:integrationId/channels"
                component={IntegrationProjectChannels}
              />
            </V3Route>
            <V3Route
              path="/integrations/slack/finish"
              component={SlackIntegrationComplete}
            />

            {/* Personal Settings Routes */}
            <V3Route
              component={ProfileSettingsShell}
              name="Personal Settings"
              hideAbstractAppButton
            >
              <V3Route path="/account/profile" component={UserProfile} />
              <V3Route path="/account/password" component={UserPassword} />
              <V3Route path="/account/emails" component={UserEmails} />
              <V3Route
                path="/account/notifications"
                component={UserNotifications}
              />
              <V3Route
                path="/account/organizations"
                component={UserOrganizations}
              />
              <V3Route path="/account/tokens" component={UserAccessTokens} />
              <V3Route path="/account/oauth" component={OAuthApplications} />
              <V3Route
                path="/organizations/:organizationId/applications/:id"
                component={OAuthApplicationDetails}
              />
            </V3Route>

            <V3Route component={SupportTicket} path="/contact-support" />

            <V3Route
              path="/projects/:projectId/branches/:branchId/collections/:collectionId"
              component={Collection}
              id="collection"
              hideHeader={{ mobile: true }}
            />

            <V3Route
              path="/projects/:projectId/branches/:branchId/collections/:collectionId/layers/:collectionLayerId"
              component={CollectionLayer}
            />

            <V3Route
              path="/projects/:projectId/branches/:branchId/commits/:sha/files/:fileId/layers/:layerId"
              component={Layer}
              hideHeader={{ mobile: true }}
              id="layer"
            />

            <V3Route
              path="/organizations/:organizationId/access/:token/accept"
              component={GrantOrganizationAccess}
            />

            <V3Route
              path="/projects/:projectId/access/:token/accept"
              component={GrantProjectAccess}
            />

            <V3Route path="/sso/verify" component={SSOTestVerification} />

            <V3Redirect path="/account" to="/account/profile" />

            <V3Route
              path="/projects/:projectId/branches/:branchId/collections/:collectionId/layers/:collectionLayerId/presentation"
              component={LayerPresentationRedirect}
            />

            <V3Redirect
              path="/projects/:projectId/branches/:branchId/collections/:collectionId/chromeless"
              to="/projects/:projectId/branches/:branchId/collections/:collectionId"
            />

            <V3Redirect
              path="/projects/:projectId/branches/:branchId/collections/:collectionId/modal"
              to="/projects/:projectId/branches/:branchId/collections/:collectionId"
            />

            <V3Redirect
              path="/projects/:projectId/branches/:branchId/collections/:collectionId/presentation"
              to="/projects/:projectId/branches/:branchId/collections/:collectionId"
            />

            <V3Redirect
              path="/organizations/:organizationId/settings"
              to="/organizations/:organizationId/edit"
            />

            <V3Redirect
              path="/organizations/:organizationId/details"
              to="/organizations/:organizationId/edit/details"
            />

            <V3Redirect
              path="/organizations/:organizationId/subscription"
              to="/organizations/:organizationId/edit/subscription"
            />

            <V3Redirect
              path="/organizations/:organizationId/integrations/*"
              to="/organizations/:organizationId/edit/integrations"
            />

            <V3Redirect
              path="/projects/:projectId/branches/:branchId/conversation"
              to="/projects/:projectId/branches/:branchId/activity"
            />

            <V3Redirect
              path="/projects/:projectId/branches/:branchId/activity"
              to="/projects/:projectId/branches/:branchId/overview"
            />
          </V3Route>
        </V3Route>
        <V3Route
          path="/oauth/authorize"
          component={AuthorizeOAuthApplication}
        />
        <V3Route path="/oauth/authorize/result" component={PluginRedirect} />
      </V3Route>
    </V3Route>
    <V3Route path="/share/:shareId" component={Share} />
    <V3Route component={AppShell}>
      <V3Route path="*" component={NotFound} name="404" />
    </V3Route>
  </V3Routes>
);

invariant(root, "Root required");
enableSentry(SENTRY_DSN, routes, [
  Sentry.reactRouterV5BrowserTracingIntegration({ history }),
]);
trackHistory(analytics);
trackVisits();
removeExperiments();
setAppElement(root);

render(
  <React.StrictMode>
    <Provider store={store}>
      <StoreLoader>
        <Theme.Provider>
          <PusherProvider>
            <ModalRouter>{routes}</ModalRouter>
          </PusherProvider>
        </Theme.Provider>
      </StoreLoader>
    </Provider>
  </React.StrictMode>,
  root
);

if (window.performance) {
  window.performance.measure("AppReady", "AppLoad");
}
